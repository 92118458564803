import React, { Component } from 'react';
import { FormGroup, FormControlLabel, Checkbox, Typography, Box } from '@material-ui/core';

export default class MenuForm extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  handleChange(menu, checked){
    const selectedMenus = this.props.selectedMenus.slice();
    if(checked){
      selectedMenus.push(menu);
    } else {
      const index = selectedMenus.findIndex((m) => m.id === menu.id);
      selectedMenus.splice(index, 1);
    }
    this.props.onChange(selectedMenus);
  }

  render(){
    const { menus, selectedMenus } = this.props;
    return (
      <React.Fragment>
        <Box marginBottom={1}>
          <Typography>メニューを選択してください</Typography>
          <Typography variant="caption">※ 複数選択可</Typography>
        </Box>
        <FormGroup>        
        {menus.map((menu,i) => {
          const checked = selectedMenus.some((m) => m.id === menu.id);
          return (
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => this.handleChange(menu, !checked)}
                />
              }
              label={menu.name}
            />
          );
        })}
        </FormGroup>
      </React.Fragment>      
    );
  }
  
}

