import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Checkout from "./containers/Checkout";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#c5cae9"
    },
    secondary: {
      main: "#ec407a"
    }
  },
  typography: {
    fontSize: 12
  }
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Checkout />
    </ThemeProvider>
  );
}
