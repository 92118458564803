import React, { Component } from "react";
import moment from "moment";
import axios from "axios";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  colors,
  Link,
  Button,
  Icon,
  CircularProgress,
  Typography
} from "@material-ui/core";
import { getWeekDayString } from "../Utility";
import * as holiday_jp from "@holiday-jp/holiday_jp";

const TIME_FRAME = 30;
const OPEN_TIME = {
  hours: 9,
  minutes: 30
};
const CLOSE_TIME = {
  hours: 20,
  minutes: 0
};

export default class DateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment()
        .startOf("day")
        .add(1, "day"),
      calenderData: null
    };
  }

  componentDidMount() {
    this.loadCalenderData();
  }

  componentWillUnmount() {
    this.setState({ calenderData: null });
  }

  loadCalenderData() {
    const requiredMinutes = this.props.menus
      .map(m => m.minutes)
      .reduce((minutes, sum) => minutes + sum);
    this.setState({ isLoading: true }, () => {
      axios
        .get("https://api.salon.9bo.jp/calendar?courseTime=" + requiredMinutes)
        .then(response => {
          this.setState({ calenderData: response.data, isLoading: false });
        });
    });
  }

  isAvailable(date) {
    const { calenderData } = this.state;
    return (
      calenderData && calenderData[date.format("YYYY-MM-DD HH:mm")] === true
    );
  }

  getHeaderCellStyle(d) {
    const style = { top: "25px" };

    if (holiday_jp.isHoliday(d.toDate())) {
      style.color = colors.red[500];
      style.backgroundColor = colors.red[50];
    } else {
      switch (d.day()) {
        case 0:
          style.color = colors.red[500];
          style.backgroundColor = colors.red[50];
          break;
        case 6:
          style.color = colors.blue[500];
          style.backgroundColor = colors.blue[50];
          break;
        default:
          break;
      }
    }

    return style;
  }

  getDays() {
    const { date } = this.state;
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(moment(date).add(i, "day"));
    }
    return days;
  }

  getTimes() {
    const { date } = this.state;
    const times = [];
    const closeTime = moment(date)
      .hours(CLOSE_TIME.hours)
      .minutes(CLOSE_TIME.minutes);
    let time = moment(date)
      .hours(OPEN_TIME.hours)
      .minutes(OPEN_TIME.minutes);
    while (!time.isAfter(closeTime)) {
      times.push(moment(time));
      time.add(TIME_FRAME, "minute");
    }
    return times;
  }

  createMonthHeader(days) {
    const months = days.map(d => d.format("M月"));
    const index = months.findIndex(m => m !== months[0]);

    if (index < 0) {
      return (
        <TableCell colSpan={7} align="center" height="25px">
          {months[0]}
        </TableCell>
      );
    } else {
      return (
        <React.Fragment>
          <TableCell colSpan={index} align="center" height="25px">
            {months[0]}
          </TableCell>
          <TableCell colSpan={7 - index} align="center" height="25px">
            {months[index]}
          </TableCell>
        </React.Fragment>
      );
    }
  }

  handleSelect(date) {
    this.props.onSelect(date);
  }

  handleBack() {
    this.setState({ date: moment(this.state.date).add(-1, "week") });
  }

  handleNext() {
    this.setState({ date: moment(this.state.date).add(1, "week") });
  }

  isDisableBack() {
    const tomorrow = moment()
      .startOf("day")
      .add(1, "day");
    return !moment(this.state.date).isAfter(tomorrow);
  }

  isDisableNext() {
    return moment(this.state.date).diff(moment(), "week") >= 3;
  }

  render() {
    const { calenderData, isLoading } = this.state;
    const days = this.getDays();
    const times = this.getTimes();

    return (
      <React.Fragment>
        <Box marginBottom={2}>
          <Typography>来店日時を選択してください</Typography>
        </Box>
        {isLoading && (
          <Box display="flex" justifyContent="center" padding={2}>
            <CircularProgress color="secondary" />
          </Box>
        )}
        {calenderData && (
          <React.Fragment>
            <Box display="flex" marginBottom={1}>
              <Box flexGrow={1}>
                <Button
                  size="small"
                  variant="outlined"
                  disabled={this.isDisableBack()}
                  onClick={() => this.handleBack()}
                >
                  <Icon fontSize="small">chevron_left</Icon>
                  前の1週間
                </Button>
              </Box>
              <Box>
                <Button
                  justify="flex-end"
                  size="small"
                  variant="outlined"
                  disabled={this.isDisableNext()}
                  onClick={() => this.handleNext()}
                >
                  次の1週間
                  <Icon fontSize="small">chevron_right</Icon>
                </Button>
              </Box>
            </Box>
            <Box>
              <Table
                stickyHeader
                padding="none"
                style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      rowSpan={2}
                      style={{ width: 54, zIndex: 2 }}
                    ></TableCell>
                    {this.createMonthHeader(days)}
                  </TableRow>
                  <TableRow>
                    {days.map((d, i) => (
                      <TableCell
                        key={i}
                        align="center"
                        style={this.getHeaderCellStyle(d)}
                      >
                        <div>{d.format("DD")}</div>
                        <div>({getWeekDayString(d)})</div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {times.map((t, i) => (
                    <TableRow key={i}>
                      <TableCell variant="head" align="center">
                        {t.format("HH:mm")}
                      </TableCell>
                      {days.map((d, j) => {
                        const date = moment(d)
                          .hours(t.hours())
                          .minutes(t.minutes());
                        return (
                          <TableCell
                            key={j}
                            align="center"
                            style={{
                              paddingTop: 5,
                              paddingBottom: 5,
                              fontSize: 16
                            }}
                          >
                            {this.isAvailable(date) ? (
                              <Link
                                color="secondary"
                                component="button"
                                onClick={() => this.handleSelect(date)}
                                style={{ fontSize: 16 }}
                              >
                                ◎
                              </Link>
                            ) : (
                              <span style={{ color: colors.grey[500] }}>×</span>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
