import React, { Component } from 'react';
import { TextField, Box, Typography } from '@material-ui/core';

export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.initValidation(props.contact);
  }

  initValidation(contact){
    const inputCheck = {};
    for (let key of Object.keys(contact)){
      inputCheck[key] = this.validate(key, contact[key]);
    }
    if(this.props.onChange){
      this.props.onChange(contact, inputCheck);
    }
  }

  handleChange(key, val){    
    const contact = Object.assign({}, this.props.contact);
    contact[key] = val;
    const inputCheck = Object.assign({}, this.props.inputCheck);
    inputCheck[key] = this.validate(key, val);
    if(this.props.onChange){
      this.props.onChange(contact, inputCheck);
    }
  }

  validate(key, val){
    switch(key){
      case 'name':
        if(val.length === 0){
          return '名前を入力してください';
        }
        if(val.length > 50){
          return '50文字以内で入力してください';
        }
        return '';

      case 'email':
        if(val.length === 0){
          return 'メールアドレスを入力してください';
        }
        const regx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(!regx.test(val)){
          return '正しいメールアドレスの形式で入力してください';
        }
        return '';

      case 'memo':
        if(val.length > 200) {
          return '200文字以内で入力してください';
        }
        return '';

      default:
        return '';
    }
  }

  saveData(){

  }

  loadData(){
    
  }

  render(){
    const { contact, inputCheck } = this.props;

    return (
      <React.Fragment>
        <Box marginBottom={2}>
          <Typography>お客様の情報を入力してください</Typography>
        </Box>
        <form>
          <TextField required
            id="name"
            label="お名前"
            value={contact.name}
            onChange={(e) => this.handleChange('name', e.target.value)}
            margin="normal"
            fullWidth
            error={inputCheck.name.length > 0}
            helperText={inputCheck.name}
          />
          <TextField required
            id="email"
            label="メールアドレス"
            value={contact.email}
            onChange={(e) => this.handleChange('email', e.target.value)}
            margin="normal"
            fullWidth
            error={inputCheck.email.length > 0}
            helperText={inputCheck.email}          
          />
          <TextField
            id="memo"
            label="備考"
            value={contact.memo}
            onChange={(e) => this.handleChange('memo', e.target.value)}
            margin="normal"
            fullWidth
            multiline
            error={inputCheck.memo.length > 0}
            helperText={inputCheck.memo}
          />
        </form>
      </React.Fragment>      
    );
  }
  
}

