import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import { createDateString } from "../Utility";

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: "700",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    backgroundColor: theme.palette.primary.main
  },
  label: {
    fontWeight: "700"
  },
  value: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    wordBreak: "break-all",
    wordWrap: "break-word"
  }
}));

export default function Review(props) {
  const { menus, date, contact } = props.reservation;

  return (
    <React.Fragment>
      <Box marginBottom={2}>
        <Typography>
          予約内容に間違いがなければ「予約する」をクリックしてください
        </Typography>
      </Box>
      <Box marginBottom={1}>
        <Title>予約内容</Title>
        <Item label="来店日時" value={createDateString(date)} />
        <Item
          label="メニュー"
          value={menus.map(menu => menu.name).join(", ")}
        />
      </Box>
      <Box marginBottom={1}>
        <Title>お客様情報</Title>
        <Item label="お名前" value={contact.name} />
        <Item label="メールアドレス" value={contact.email} />
        <Item label="備考" value={contact.memo} />
      </Box>
    </React.Fragment>
  );
}

function Title(props) {
  const classes = useStyles();
  return (
    <Typography align="center" gutterBottom className={classes.title}>
      {props.children}
    </Typography>
  );
}

function Item(props) {
  const classes = useStyles();
  const { label, value } = props;
  return (
    <div>
      <Typography gutterBottom className={classes.label}>
        {label}
      </Typography>
      <Typography gutterBottom className={classes.value}>
        {value}
      </Typography>
    </div>
  );
}
