import * as holiday_jp from "@holiday-jp/holiday_jp";

export function getWeekDayString(date) {
  switch (date.day()) {
    case 0:
      return "日";
    case 1:
      return "月";
    case 2:
      return "火";
    case 3:
      return "水";
    case 4:
      return "木";
    case 5:
      return "金";
    case 6:
      return "土";
    default:
      return "";
  }
}

export function createDateString(datetime) {
  const date = datetime.format("YYYY年M月D日");
  const time = datetime.format("HH:mm");
  const dayOfWeek =
    getWeekDayString(datetime) +
    (holiday_jp.isHoliday(datetime.toDate()) ? "・祝" : "");
  return `${date}（${dayOfWeek}） ${time}`;
}
