import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Backdrop,
  CircularProgress
} from "@material-ui/core";

import MenuForm from "../components/MenuForm";
import DateForm from "../components/DateForm";
import ContactForm from "../components/ContactForm";
import Review from "../components/Review";
import axios from "axios";

import { createDateString } from "../Utility";

const STEPS = [
  "メニューを選ぶ",
  "日時を指定する",
  "お客様情報を入力",
  "予約内容の確認"
];
const MENUS = [
  { id: 1, name: "ハンド", minutes: 180 },
  { id: 2, name: "フット", minutes: 180 }
];

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
});

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      reservation: {
        menus: [],
        date: null,
        contact: {
          name: "",
          email: "",
          memo: ""
        }
      },
      inputCheck: {
        name: "",
        email: "",
        memo: ""
      },
      errorMessage: null,
      isLoading: false
    };
  }

  createReservation() {
    this.setState({ isLoading: true }, () => {
      axios
        .post("https://api.salon.9bo.jp/calendar", this.state.reservation, {
          responseType: "json"
        })
        .then(response => {
          this.setState({ activeStep: this.state.activeStep + 1 });
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data.errors);
            const messages = [];
            for (let k of Object.keys(error.response.data.errors)) {
              error.response.data.errors[k].forEach(msg => {
                messages.push(msg);
              });
            }
            const errorMessage = (
              <div>
                <p>下記の理由により予約できませんでした。</p>
                <ul>
                  {messages.map(msg => (
                    <li>{msg}</li>
                  ))}
                </ul>
              </div>
            );
            this.setState({ errorMessage: errorMessage });
          }
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    });
  }

  handleNext() {
    if (this.state.activeStep === 3) {
      this.createReservation();
    } else {
      this.setState({ activeStep: this.state.activeStep + 1 });
    }
  }

  handleBack() {
    this.setState({ activeStep: this.state.activeStep - 1 });
  }

  handleChaneMenus(menus) {
    const reservation = Object.assign({}, this.state.reservation);
    reservation.menus = menus;
    this.setState({ reservation: reservation });
  }

  handleSelectDate(date) {
    const reservation = Object.assign({}, this.state.reservation);
    reservation.date = date;
    this.setState({
      reservation: reservation,
      activeStep: this.state.activeStep + 1
    });
  }

  handleChangeContact(contact, inputCheck) {
    const reservation = Object.assign({}, this.state.reservation);
    reservation.contact = contact;
    this.setState({ reservation: reservation, inputCheck: inputCheck });
  }

  getStepContent(step) {
    const { reservation, inputCheck } = this.state;
    const { menus, contact } = reservation;
    switch (step) {
      case 0:
        return (
          <MenuForm
            menus={MENUS}
            selectedMenus={menus}
            onChange={menus => this.handleChaneMenus(menus)}
          />
        );
      case 1:
        return (
          <DateForm
            menus={menus}
            onSelect={date => this.handleSelectDate(date)}
          />
        );
      case 2:
        return (
          <ContactForm
            contact={contact}
            inputCheck={inputCheck}
            onChange={(contact, inputCheck) =>
              this.handleChangeContact(contact, inputCheck)
            }
          />
        );
      case 3:
        return <Review reservation={reservation} />;

      case 4:
        return <div>ご予約ありがとうございます。予約を受け付けました。</div>;

      default:
        throw new Error("Unknown step");
    }
  }

  disableReserve() {
    const { inputCheck } = this.state;
    for (let key of Object.keys(inputCheck)) {
      if (inputCheck[key].length > 0) return true;
    }
    return false;
  }

  disableToMoveNext() {
    const { activeStep } = this.state;

    switch (activeStep) {
      case 0:
        return this.state.reservation.menus.length === 0;

      case 2:
        return this.disableReserve();

      case 3:
        return false;

      default:
        return true;
    }
  }

  shouldShowNextButton() {
    const { activeStep } = this.state;
    switch (activeStep) {
      case 1:
        return false;

      default:
        return true;
    }
  }

  render() {
    const { classes } = this.props;
    const { activeStep, errorMessage, isLoading } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar position="absolute" color="primary" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" noWrap>
              R　予約フォーム
            </Typography>
          </Toolbar>
        </AppBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h6" align="center">
              来店予約
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {STEPS.map(label => (
                <Step key={label}>
                  <StepLabel></StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === STEPS.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    ご予約ありがとうございます。
                  </Typography>
                  <Typography variant="subtitle1">
                    下記の日時で予約を受け付けました。
                    <br />・{createDateString(this.state.reservation.date)}〜
                    <br />
                    ご入力いただいたメールアドレスにメールをお送り致しましたので、ご確認ください。
                    <br />
                    ご来店をお待ちしております。
                    <br />
                  </Typography>
                  <div className={classes.buttons}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        window.location.reload();
                      }}
                      className={classes.button}
                    >
                      TOPへ戻る
                    </Button>
                  </div>
                  <Button></Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {this.getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button
                        onClick={() => this.handleBack()}
                        className={classes.button}
                      >
                        戻る
                      </Button>
                    )}
                    {this.shouldShowNextButton() && (
                      <Button
                        variant="contained"
                        disabled={this.disableToMoveNext()}
                        color="primary"
                        onClick={() => this.handleNext()}
                        className={classes.button}
                      >
                        {activeStep === STEPS.length - 1 ? "予約する" : "次へ"}
                      </Button>
                    )}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
          <Dialog
            open={errorMessage != null}
            onClose={() => this.setState({ errorMessage: null, activeStep: 0 })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">予約エラー</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {errorMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  this.setState({ errorMessage: null, activeStep: 0 })
                }
                color="primary"
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </main>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Checkout);
